import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-more-popover',
  templateUrl: 'more-popover.component.html',
})
export class MorePopoverComponent{
  @Input() showDuplicate = false;

  constructor(private popoverController: PopoverController) { }

  async edit() {
    await this.popoverController.dismiss({edit: true});
  }

  async delete() {
    await this.popoverController.dismiss({delete: true});
  }

  async duplicate() {
    await this.popoverController.dismiss({duplicate: true});
  }
}
