import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MorePopoverComponent } from './more-popover.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
  ],
  declarations: [MorePopoverComponent],
})
export class MorePopoverComponentModule {}
