import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
  {
    path: 'conditions',
    loadChildren: () => import('./conditions-page/conditions.module').then( m => m.ConditionsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'attributes',
    loadChildren: () => import('./attributes-page/attributes.module').then( m => m.AttributesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'gallery',
    loadChildren: () => import('./gallery-page/gallery.module').then( m => m.GalleryPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'courses',
    loadChildren: () => import('./courses-page/courses.module').then( m => m.CoursesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'course/:courseId',
    loadChildren: () => import('./course-page/course.module').then( m => m.CoursePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'course/:courseId/step/:stepId',
    loadChildren: () => import('./step-page/step.module').then( m => m.StepPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'course/:courseId/step/:stepId/substep/:substepId',
    loadChildren: () => import('./substep-page/substep.module').then( m => m.SubstepPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./auth/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'registration',
    loadChildren: () => import('./auth/registration/registration.module').then( m => m.RegistrationPageModule)
  },
  {
    path: 'tests',
    loadChildren: () => import('./tests-page/tests.module').then( m => m.TestsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'test/:testId',
    loadChildren: () => import('./test-page/test.module').then( m => m.TestPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    redirectTo: 'courses',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
