import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { User } from './auth.service';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(
    // public authService: AuthService,
    public router: Router
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>  {
    const user = JSON.parse(localStorage.getItem('user')) as User;

    if (user?.idToken && request.url.indexOf('users/firebase') === -1) {
      request = request.clone({
        setHeaders: {
          'X-Authorization-Firebase': user.idToken,
        },
      });
    }
    return next.handle(request).pipe(
      // map((event: HttpEvent<any>) => {
      //   if (event instanceof HttpResponse) {
      //       console.log('event--->>>', event);
      //   }
      //   return event;
      // }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.status === 403) {
          this.router.navigate(['login']);
        }
        return throwError(error);
    })) as any;
  }
}
