import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient } from '@angular/common/http';
// import { PartnershipStatusEnum } from '../models/partnership-status.enum';
// import { SexEnum } from '../models/sex.enum';

export interface User {
  uid: string;
  email: string;
  idToken: string;
  // displayName: string;
  // photoURL: string;
  // emailVerified: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // baseUrl = 'http://app40dni.x25.pl:8080/api/v1'; // prod
  // baseUrl = 'http://app40dni.x25.pl:8081/api/v1'; // dev

  baseUrl = 'https://api.app40dni.x25.pl/api/v1'; // prod https
  // baseUrl = 'https://api-dev.app40dni.x25.pl/api/v1'; // dev https

  userData: any; // Save logged in user data

  constructor(
    // public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    private httpClient: HttpClient
  ) {
    /* Saving user data in localstorage when
    logged in and setting up null when logged out */
    // this.afAuth.authState.subscribe((user) => {
    //   if (user) {
    //     this.userData = user;
    //     localStorage.setItem('user', JSON.stringify(this.userData));
    //     // JSON.parse(localStorage.getItem('user'));
    //   } else {
    //     localStorage.setItem('user', null);
    //     // JSON.parse(localStorage.getItem('user'));
    //   }
    // });
  }

  // Sign in with email/password
  async signIn(email: string, password: string) {
    try {
      const result = await this.afAuth.signInWithEmailAndPassword(
        email,
        password
      );
      await this.setUserData(result);
      // localStorage.setItem('user', JSON.stringify(this.userData));
      // this.ngZone.run(() => {
      this.router.navigate(['courses'], { replaceUrl: true });
      // });
    } catch (error) {
      window.alert(error.message);
    }
  }

  // signIn(email: string, password: string) {
  //   this.afAuth.signInWithEmailAndPassword(
  //     email,
  //     password
  //   ).then(result => {
  //     await this.setUserData(result);
  //     this.ngZone.run(() => {
  //       this.router.navigate(['courses']);
  //     });
  //   })
  //   .catch(error => {
  //     window.alert(error.message);
  //   });
  // }

  // Sign up with email/password
  async signUp(
    email: string,
    password: string,
    // name: string,
    // sex: SexEnum,
    // partnershipStatus: PartnershipStatusEnum
  ) {
    try {
      const result = await this.afAuth.createUserWithEmailAndPassword(
        email,
        password
      );
      const token = await result.user.getIdToken();
      this.httpClient
        .post<any>(
          this.baseUrl + '/users/firebase',
          {
            // sex,
            // partnershipStatus,
            name,
          },
          { headers: { firebaseToken: token } }
        )
        .subscribe((res: any) => {
          console.log(res);
          // result.user.sendEmailVerification()
          // .then(() => {
          //   this.router.navigate(['login']);
          // });
          this.ngZone.run(() => {
            this.router.navigate(['login']);
          });
        });
    } catch (error) {
      window.alert(error.message);
    }
  }

  // // Send email verfificaiton when new user sign up
  // SendVerificationMail() {
  //   return this.afAuth.auth.currentUser.sendEmailVerification()
  //   .then(() => {
  //     this.router.navigate(['verify-email-address']);
  //   })
  // }

  // Reset Forggot password
  async forgotPassword(passwordResetEmail: string) {
    try {
      await this.afAuth.sendPasswordResetEmail(passwordResetEmail);
      window.alert('Link do zmiany hasła został wysłany. Sprawdź swoją skrzynkę email.');
    } catch (error) {
      window.alert(error);
    }
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user !== null ? true : false;
  }

  // // Sign in with Google
  // GoogleAuth() {
  //   return this.AuthLogin(new auth.GoogleAuthProvider());
  // }

  // // Auth logic to run auth providers
  // AuthLogin(provider) {
  //   return this.afAuth.auth.signInWithPopup(provider)
  //   .then((result) => {
  //      this.ngZone.run(() => {
  //         this.router.navigate(['dashboard']);
  //       })
  //     this.SetUserData(result.user);
  //   }).catch((error) => {
  //     window.alert(error)
  //   })
  // }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  async setUserData(userCredential: firebase.auth.UserCredential) {
    // const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const idToken = await userCredential.user.getIdToken();
    const userData: User = {
      uid: userCredential.user.uid,
      email: userCredential.user.email,
      idToken
      // displayName: user.displayName,
      // photoURL: user.photoURL,
      // emailVerified: user.emailVerified
    };
    localStorage.setItem('user', JSON.stringify(userData));
    // return userRef.set(userData, {
    //   merge: true
    // })
  }

  // Sign out
  async signOut() {
    try {
      await this.afAuth.signOut();
      localStorage.removeItem('user');
      this.router.navigate(['login']);
    } catch (error) {
      window.alert(error);
    }
  }
}
